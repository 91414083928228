



























































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {getPageAPIs, Util} from "@/common/util";
import GoodsClassAPIClass from "@/common/api/goodsClass";
export const pageApiList = getPageAPIs(GoodsClassAPIClass);
@Component({})
export default class UserList extends Vue {
  private buyAddressApi: any = new GoodsClassAPIClass();
  private sellShow: boolean = false;
  private forms: any = {
    value: 0,
    input: "",
  };
  private form: any = {
    time: [], // 时间选择
  };

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.buyAddressApi.categorylists(
        {
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.count = res.data.count;
          this.tablePage.page = res.data.page;
          this.tablePage.prepage = res.data.prepage;
        }
    );
  }

  private title: string = "";

  private setgoodsClass(data: any): void {
    this.restForms();
    if (data == 1) {
      this.title = "添加分类";
    } else {
      this.title = "编辑分类";
      this.forms = {...data};
    }
    this.sellShow = true;
  }

  private submit(): void {
    if (this.title == "添加分类") {
      this.buyAddressApi.categoryadd(this.forms, () => {
        this.sellShow = false;
        Util.showToast("新增成功");
        this.search();
      });
    } else {
      this.buyAddressApi.categorysave(this.forms, () => {
        this.sellShow = false;
        Util.showToast("修改成功");
        this.search();
      });
    }
  }

  private categorydelete(id: string): void {
    this.buyAddressApi.categorydelete(
        {
          id: id,
        },
        () => {
          Util.showToast("删除成功");
          this.search();
        }
    );
  }

  private restForms(): void {
    this.forms = new Util().clearObject(this.forms);
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.count = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    count: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
}

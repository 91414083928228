import { render, staticRenderFns } from "./goodSale.vue?vue&type=template&id=10d9c99d&scoped=true&"
import script from "./goodSale.vue?vue&type=script&lang=ts&"
export * from "./goodSale.vue?vue&type=script&lang=ts&"
import style0 from "./goodSale.vue?vue&type=style&index=0&id=10d9c99d&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10d9c99d",
  null
  
)

export default component.exports

import Vue from 'vue'
import {Component} from "vue-property-decorator";
import buyAddressApi from "@/common/api/business";
import {Util} from "@/common/util";

@Component({})
export default class GoodSale extends Vue {
  private buyAddressApi: any = new buyAddressApi();
  private visible:boolean = false
  private isLoading:boolean = false
  private ischeck_goods: number = 1;
  private category: any = [];
  private goodsList: any = [];
  private selectgoodsList: any = [];
  private goodsprice: number = 0;
  private forms: any = {
    category_id: 0,
  };
  private selectgoods: any = new Set();
  private order_id:string = ''
  private tableinfo:any = {}
  private show(order_id:string,tableinfo:any):void{
    this.order_id = order_id
    this.tableinfo = tableinfo
    this.goodsell();
  }
  private goodsell(): void {
    this.getscanCode(1);
    this.goodsprice = 0;
    this.restForms();
    this.ischeck_goods = 1;
    this.goodscategory();
    this.goodslists();
    this.selectgoodsList = [];
    this.selectgoods = new Set();
    this.visible = true
  }
  private restForms(): void {
    this.forms = new Util().clearObject(this.forms);
    this.forms.category_id = 0;
  }
  private goodscategory(): void {
    this.buyAddressApi.goodscategory({}, (res: any) => {
      this.category = res.data.list;
    });
  }

  private scanCodePay(): void {
    this.scanCode = true;
    this.getscanCode(2);
  }
  private inputnum(): void {
    this.getgoodsprice();
  }
  private goodcode: string = "";
  private scanCodeadd(): void {
    this.buyAddressApi.goodscan(
        {
          bar_code: this.goodcode,
        },
        (res: any) => {
          this.selectgood(res.data.item);
        }
    );
  }

  private removegood(row: any): void {
    this.selectgoods.delete(row);
    this.selectgoodsList = [...this.selectgoods];
    this.getgoodsprice();
  }
  private selectgood(row: any): void {
    row.num = 1;
    if (this.selectgoods.size > 0) {
      this.selectgoods.forEach((item: any) => {
        console.log(item);
        if (item.id != row.id) {
          this.selectgoods.add(row);
        }
      });
    } else {
      this.selectgoods.add(row);
    }
    this.selectgoodsList = [...this.selectgoods];
    this.getgoodsprice();
  }
  private getgoodsprice(): void {
    this.goodsprice = 0;
    for (let i of this.selectgoodsList) {
      this.goodsprice += i.num * i.price;
    }
  }
  private async buygoods() {
    // let goods = "";
    // for (let i of this.selectgoodsList) {
    //   goods += i.id + "=" + i.num + ",";
    // }
    if(this.isLoading){
      return;
    }
    this.isLoading = true
    let goods = this.selectgoodsList.map((item:any)=>{
      return `${item.id}=${item.num}`
    }).toString()
    if (this.order_id) {
      this.buyAddressApi.goodsSalecart(
          {
            order_id: this.order_id,
            cart_goods: goods,
            desk_id:this.tableinfo.id,
          },
          () => {
            Util.showToast("添加成功");
            this.$emit('success')
            this.visible = false;
            setTimeout(()=>{
              this.isLoading = false
            },300)
          },()=>{
            setTimeout(()=>{
              this.isLoading = false
            },300)
          }
      );
    } else {
      this.buyAddressApi.goodsSale({ goods: goods }, (res: any) => {
        // this.sellShow = false;
        // this.orderinfo = res.data;
        // this.pay = true;
        // this.userinfo.id = "";
        // this.msg = "";
        // this.phone = "";
        Util.showToast("添加成功");
        this.$emit('success')
        this.visible = false;
      });
    }
  }
  private goodslists(): void {
    this.buyAddressApi.goodsquery(
        {
          ...this.forms,
          page: 1,
          limit: 200,
        },
        (res: any) => {
          this.goodsList = res.data.list;
        }
    );
  }

  private setnum(type: number, row: any): void {
    if (type == 0) {
      if (row.num > 1) {
        row.num--;
      }
    } else {
      row.num++;
    }
    this.selectgoodsList = [...this.selectgoodsList];
    this.getgoodsprice();
  }

  private getscanCode(type: number): void {
    let that = this;
    let str = "";
    document.onkeydown = async function (e: any) {
      console.log(str);
      if (e.target.tagName !== "BODY") {
        str = "";
      } else {
        if (e.keyCode !== 13) {
          if (e.keyCode >= 32 && e.keyCode <= 126) {
            let k = e.key;
            str += k;
          }
        } else {
          if (str) {
            console.log(str);
            if (type == 1) {
              that.goodcode = str;
              str = "";
              that.scanCodeadd();
            } else {
              if (that.auth_code != "") {
                console.log(7777);
                let res = await that.buygoods();
                console.log(res);
                if (res == 0) {
                  that.auth_code = str;
                  str = "";
                  that.payrequest();
                }
              } else {
                that.auth_code = str;
                str = "";
                that.payrequest();
              }
            }
          }
        }
      }
    };
  }
}
